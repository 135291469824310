import * as React from "react"
import {HeroSection} from "../components/landingpage/heroSection";
import {Competences} from "../components/landingpage/competences";
import {VideoIntro} from "../components/landingpage/video-wealcome-introduction";
import {Header} from "../components/header";
import {ProjectBuildings} from "../components/landingpage/projectsBuilding";
import {Training} from "../components/landingpage/training";
import {Customers} from "../components/landingpage/customers";
import {Testimonials} from "../components/landingpage/testimonials";
import {Footer} from "../components/landingpage/footer";
import {WhoAreWe} from "../components/landingpage/whoAreWe";
import {MetaTags} from "../components/metaTags";

const title = "Le TDD et la Clean Architecture pour un code de qualité.";
const metaDescription = "Experts en méthodes de programmation avancées " +
    "telles que TDD et la Clean Architecture. Nous codons vos projets et vous formons.";

export default () => {
    return (
        <div>
            <MetaTags title={title} description={metaDescription}/>
            <Header fixedTop={true} backgroundTransition={true}/>
            <div className="main">
                <HeroSection/>
                <Competences/>
                <VideoIntro/>
                <ProjectBuildings/>
                <Training/>
                <WhoAreWe/>
                <Customers/>
                <Testimonials/>
            </div>
            <Footer whiteBackground={true}/>
        </div>
    )
};
