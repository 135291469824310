import React from "react";
import "./video-wealcome-introduction.scss";

export const VideoIntro = () => {

    return (
        <React.Fragment>
            <section id="videopresentation"
                className="ptb-100 background-img video-intro">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="video-promo-content mt-4 text-center">
                                <a href="https://www.youtube.com/watch?v=XwmXyylX6y0"
                                   className="popup-youtube video-play-icon d-inline-block">&nbsp;</a>
                                <h5 className="mt-4 text-white">Vidéo de présentation de WealCome</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );

};
