import React from "react";

export const Competences = () => {
    return (
        <section id="competences" className="competences pt-5 pb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-md-8">
                        <div className="section-heading text-center mb-5">
                            <h1>Des compétences rares sur le marché</h1>
                            <p className="lead">
                                <i>A code that works is <strong>not</strong> enough! (Robert C. Martin)</i><br/>
                                Nous maîtrisons des <strong>méthodes de programmation
                                élitistes</strong> faisant de vos projets de <strong>véritables succès</strong>.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row no-gutters">
                    <div
                        className="col-lg-6 col-md-6 col-sm-6 col-12"
                    >
                        <div
                            className="single-services single-feature-hover single-feature text-center p-5 h-100">
                            <i className="lni lni-cup"/>
                            <div className="feature-content">
                                <h5>Test-Driven Development (TDD)</h5>
                                <p>
                                    Nous sommes <strong>experts en TDD</strong>, ce qui assure une qualité et fiabilité
                                    du code sans
                                    compromis;
                                    tout en augmentant la <strong>productivité</strong> de nos développeurs.<br/>
                                    Un élément crucial pour <strong>designer son code</strong> de manière optimale.<br/>
                                    Une fois qu'on y a goûté on ne peut plus s'en passer.<br/>
                                    Chaque projet que nous réalisons <strong>respecte scrupuleusement la
                                    discipline</strong> TDD.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-6 col-md-6 col-sm-6 col-12"
                    >
                        <div
                            className="single-services single-feature-hover single-feature text-center p-5 h-100">
                            <i className="lni lni-cup"/>
                            <div className="feature-content">
                                <h5>Clean Architecture</h5>
                                <p>
                                    Un logiciel qui marche c'est bien, mais s'il devient vite obsolète, difficilement
                                    testable unitairement et
                                    difficilement évolutif, <strong>à quoi bon un tel code ?</strong><br/>
                                    Avec ce style d'architecture, aussi dénommée <strong>Hexagonal Architecture</strong>,
                                    votre produit sera <strong>très faiblement dépendant des choix
                                    techniques</strong> effectués,
                                    ce qui permettra de le <strong>tester aisément unitairement</strong> et
                                    d'assurer une migration
                                    vers de <strong>futurs</strong> frameworks, bibliothèques et base de données
                                    d'un <strong>claquement de doigts</strong>.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-6 col-md-6 col-sm-6 col-12"
                    >
                        <div
                            className="single-services single-feature-hover single-feature text-center p-5 h-100">
                            <i className="lni lni-cup"/>
                            <div className="feature-content">
                                <h5>Domain-Driven Design (DDD)</h5>
                                <p>
                                    DDD désigne un <strong>ensemble intelligent de patterns stratégiques et
                                    tactiques</strong> visant à
                                    concevoir un code source respirant <strong>vos langages parlés</strong>,
                                    la <strong>précision de vos besoins
                                    exprimés</strong> ainsi que <strong>vos désirs d'évolutions</strong>.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-6 col-md-6 col-sm-6 col-12"
                    >
                        <div
                            className="single-services single-feature-hover single-feature text-center p-5 h-100">
                            <i className="lni lni-cup"/>
                            <div className="feature-content">
                                <h5>Behaviour-Driven Development (BDD)</h5>
                                <p>
                                    <strong>Stop les logiciels hors-sujets</strong>, tellement courant ! <br/>
                                    Pas chez WealCome en tout cas.<br/>
                                    <strong>Évitons les quiproquo</strong> et soulevons les plus subtiles
                                    des <strong>ambiguïtés</strong> grâce aux <strong>ateliers BDD</strong> qui
                                    permettent d'élaborer des <strong>exemples précis de comportements</strong> du
                                    logiciel
                                    nommés <strong>"scénarios"</strong>.<br/>
                                    Beaucoup de projets échouent car cette <strong>étape est malheureusement trop
                                    souvent ignorée</strong>.<br/>
                                    Sans ce type d'atelier, pas de bon projet.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-6 col-md-6 col-sm-6 col-12"
                    >
                        <div
                            className="single-services single-feature-hover single-feature text-center p-5 h-100">
                            <i className="lni lni-cup"/>
                            <div className="feature-content">
                                <h5>Art du refactoring</h5>
                                <p>
                                    Remanier du code existant <strong>sans en changer le comportement</strong> est une
                                    pratique nommée <strong>Refactoring</strong>.<br/>
                                    Le refactoring est une <strong>activité permanente d'un développeur digne de ce
                                    nom</strong>.<br/>
                                    La crainte ? "Casser" le code d'autrui voire son propre code,
                                    entraînant des blocages complexes à résoudre.<br/>
                                    Le Refactoring est un <strong>véritable art</strong> et nécessite des <strong>compétences
                                    variées très
                                    avancées</strong> que nous maîtrisons à la perfection.<br/>
                                    Notre <strong>pratique TDD</strong> permet d'assurer un Refactoring <strong>sans le
                                    moindre stress ni la
                                    moindre mauvaise surprise</strong>.<br/>
                                    Unique objectif : <strong>malaxer le code continuellement</strong> pour <strong>répondre
                                    sans peine</strong> à tout besoin client.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-6 col-md-6 col-sm-6 col-12"
                    >
                        <div
                            className="single-services single-feature-hover single-feature text-center p-5 h-100">
                            <i className="lni lni-cup"/>
                            <div className="feature-content">
                                <h5>Expertise 100% Full Stack</h5>
                                <p>
                                    <strong>Aussi à l'aise dans le monde Front que dans le monde Back</strong>.
                                </p>
                                <p>Nous sommes <strong>experts dans plusieurs langages et
                                    technologies</strong> différentes dont voici un
                                    échantillon :<br/><br/>
                                    <strong>Java, Scala, TypeScript, Node.js, Javascript, Ruby, PHP, Angular, React, Vue,
                                        Redux, React-native, HTML/CSS3/SASS, NoSQL, SQL.</strong><br/><br/>
                                    Autrement dit, nous <strong>maîtrisons les langages fonctionnels et les langages
                                        orientés objets </strong>;
                                    avec un gros faible pour les <strong>langages fonctionnels</strong> comme Scala
                                    notamment, très
                                    profonds et si bien pensés.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};