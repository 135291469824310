import * as React from "react";
import founderImg from "../../img/michael-azerhad-formateur-tdd.jpeg";

export const WhoAreWe = () => {
    return <section id="fondateur" className="whoarewe-section ptb-100 gray-light-bg">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-7 col-md-8">
                    <div className="section-heading text-center mb-5">
                        <h1>Fondateur</h1>
                        <div>
                            <p className="lead">
                                Michaël AZERHAD - Président de WealCome
                            </p>
                        </div>
                        <img src={founderImg} alt="Michaël AZERHAD - Président de WealCome"
                             className="img-fluid" style={{borderRadius: "50%"}}/>
                        <p>
                            Michaël est passionné par tout ce qui a trait à la conception logicielle parfaite.<br/>
                            Il découvre notamment <strong>Test-Driven Development</strong> et la <strong>Clean
                            Architecture</strong> en 2011 et c'est
                            le coup de coeur.
                        </p>
                        <p>
                            Conscient que <strong>les développeurs sensibles à la qualité de code sont réellement
                            rares</strong> dans
                            le milieu IT,
                            Michaël a décidé de fonder WealCome afin de mettre en valeur ses pratiques
                            et <strong>accompagner toute personne motivée par l'excellence en programmation</strong>.
                        </p>
                        <p>
                            Vous pouvez notamment retrouver <a
                            href="https://www.linkedin.com/in/michael-azerhad/" target="_blank"
                            rel="noopener noreferrer"> Michaël
                            sur
                            Linkedin</a> où il est
                            très actif en postant régulièrement ou encore sur <a
                            href="https://stackoverflow.com/users/985949/mik378?tab=profile" target="_blank"
                            rel="noopener noreferrer">Stackoverflow</a> avec
                            une activité conséquente.
                        </p>
                        <p>
                            Michaël crée aussi des <strong>articles, podcasts et vidéos pégagogiques</strong> que vous
                            pouvez
                            retrouver sur <a href="/blog" target="_blank">notre blog</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
};