import React from "react";
import training from "../../content/landingpage/training.yaml";
import professorImg from "../../img/professor.svg";
import {interpretLineJump} from "../../helpers/interpretLineJumpsInYaml";
import qualiopiLogoImg from "../../img/LogoQualiopi-150dpi-AvecMarianne.png";
import qualiopiCertification from "../../documents/certification-qualiopi.pdf";

export const Training = () => {
    return (
        <>
            <section id="training" className="training-section ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <p className="color-secondary">
                                <strong>{training.category}</strong>
                            </p>
                            <h2 dangerouslySetInnerHTML=
                                    {{__html: interpretLineJump(training.title)}}/>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <a href={qualiopiCertification} target="_blank" rel="noopener noreferrer">
                                        <img src={qualiopiLogoImg} alt="Qualiopi" width="50%"/>
                                    </a>
                                    <p style={{color: 'gray', fontSize: 10}}>La certification qualité a été délivrée au
                                        titre de la catégorie d'action suivante<br/>
                                        <span style={{color: 'red'}}>ACTION DE FORMATION</span></p>
                                </div>
                            </div>
                            <p dangerouslySetInnerHTML=
                                   {{__html: interpretLineJump(training.description)}}/>
                        </div>
                        <div className="col-md-5">
                            <div className="service-content-left d-none d-lg-block">
                                <img
                                    src={professorImg}
                                    alt="Formateur"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
