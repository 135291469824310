import React from "react";
import img1 from "../../img/coder_feeling_proud.svg";
import img2 from "../../img/hero-animation-02.svg";
import img3 from "../../img/hero-animation-03.svg";
import img4 from "../../img/hero-animation-04.svg";
import bgWaveImg from "../../img/bg-wave.svg";
import {Link} from "gatsby";

export const HeroSection = () => {

    const tempHero = {};
    tempHero.images = {
        img1,
        img2,
        img3,
        img4
    };

    return (
        <React.Fragment>
            <section id="home" className="hero-section pt-100 background-img">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-md-8">
                            <div className="hero-content-left text-white">
                                <h1 className="text-white">Vous allez adorer <br/> notre façon de coder</h1>
                                <p className="lead">
                                    WealCome est une entreprise reconnue pour son très haut niveau de maîtrise du code et notamment
                                    des méthodes de programmation les plus avancées telles que le TDD, Clean Architecture et DDD.
                                </p>
                                <a href="https://www.youtube.com/watch?v=XwmXyylX6y0"
                                   className="popup-youtube btn solid-btn btn-primary">Vidéo de présentation</a>
                                <div className="wrapper-solid-btn-link">
                                    <Link to="/blog" className="btn solid-btn-link btn-primary">
                                        Voir notre blog
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-img">
                    <img src={bgWaveImg} alt="wave shape" className="img-fluid"/>
                </div>
            </section>
        </React.Fragment>
    );
};
