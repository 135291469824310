import React from "react";
import customers from "../../content/landingpage/customers.yaml";
import hsbcImg from "../../img/hsbc.png";
import hashnstoreImg from "../../img/hashnstore.png";
import sgImg from "../../img/societe-generale.png";
import poleEmploiImg from "../../img/pole-emploi.png";
import face2facesImg from "../../img/face2faces.png";
import blendeezImg from "../../img/blendeez.png";
import tictactripImg from "../../img/tictactrip.svg";
import eVoyageursSncf from "../../img/evoyageurs-sncf.png";
import malakoff from "../../img/malakoff_mederic.png";

export const Customers = () => {
    return (
        <>
            <section id="customers" className="ptb-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="section-heading text-center mb-5">
                                <h1>{customers.title}</h1>
                            </div>
                            <div className="row text-center d-flex flex-wrap align-items-center">
                                <div className="col-md-4" style={{paddingTop: 30}}>
                                    <img src={eVoyageursSncf} alt="E-Voyageurs SCNF"/>
                                </div>
                                <div className="col-md-4" style={{paddingTop: 20}}>
                                    <img src={poleEmploiImg} alt="Pôle Emploi"/>
                                </div>
                                <div className="col-md-4" style={{paddingTop: 80}}>
                                    <img src={hsbcImg} alt="HSBC"/>
                                </div>
                                <div className="col-md-4" style={{paddingTop: 80}}>
                                    <img src={sgImg} alt="Société Générale"/>
                                </div>
                                <div className="col-md-4" style={{paddingTop: 80}}>
                                    <img src={hashnstoreImg} alt="hashnstore"/>
                                </div>
                                <div className="col-md-4" style={{paddingTop: 20}}>
                                    <img src={malakoff} alt="E-Voyageurs SCNF"/>
                                </div>
                                <div className="col-md-4" style={{paddingTop: 20}}>
                                    <img src={blendeezImg} alt="Blendeez"/>
                                </div>
                                <div className="col-md-4" style={{paddingTop: 80}}>
                                    <img src={tictactripImg} alt="TicTacTrip" width={200}/>
                                </div>
                                <div className="col-md-4" style={{paddingTop: 80}}>
                                    <img src={face2facesImg} alt="Face2Faces"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};